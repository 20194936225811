import React, { lazy, ReactElement } from "react";
import ProjectScheduleIcon from "../components/Icon/ProjectSchedule";
import { Permission } from "../lib/permission";
import { RoleName } from "../lib/session";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { RouteProps } from "react-router";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
import {
  MdTimelapse as TimelapseIcon,
  MdSwitchCamera as SiteViewIcon,
  MdSettings as SettingsIcon,
} from "react-icons/md";
import { VscChecklist as LiftReviewIcon } from "react-icons/vsc";
import {
  GiCrane as CraneIcon,
  GiPulleyHook as CraneHookIcon,
} from "react-icons/gi";
import { RiDashboardLine as DashboardIcon } from "react-icons/ri";
import { AiFillCamera as SiteViewParentIcon } from "react-icons/ai";
import {
  RiBarChartHorizontalLine as SRPIcon,
  RiDashboardFill as DashboardIconFilled,
} from "react-icons/ri";
import { IoMdList as LRPIcon } from "react-icons/io";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { BsCardChecklist as ReviewIcon } from "react-icons/bs";

type Path = string;
export interface Route extends RouteProps {
  name: string;
  path: Path;
  permission?: Permission;
  allowedRoles: RoleName[];
  hidden?: boolean;
}

export interface MenuRoute extends Route {
  icon: ReactElement;
  parentMenu?: string;
  parentOverride?: boolean;
  parentIcon?: ReactElement;
}

export interface SettingRoute {
  name: string;
  permission?: Permission;
  subRoutes: Route[];
}

export const menuRoutes: MenuRoute[] = [
  // {
  //   name: "progress monitoring",
  //   path: "/progressMonitoring",
  //   permission: Permission.PM,
  //   allowedRoles: [RoleName.USER],
  //   icon: <ProgressMonitoringIcon />,
  //   component: lazy(() => import("../pages/ProgressMonitoring")),
  //   parentOverride: true,
  //   parentMenu: "progress monitoring",
  // },
  // {
  //   name: "dashboard",
  //   path: "/dashboard",
  //   permission: Permission.D,
  //   allowedRoles: [RoleName.USER],
  //   icon: <DashboardIconFilled />,
  //   component: lazy(() => import("../pages/Dashboard")),
  //   parentOverride: true,
  //   parentMenu: "dashboard",
  // },
  // {
  //   name: "dashboard",
  //   path: "/custom-dashboard",
  //   permission: Permission.CD,
  //   allowedRoles: [RoleName.USER],
  //   icon: <DashboardIcon />,
  //   component: lazy(() => import("../pages/CustomDashboard")),
  //   parentMenu: "Dashboard",
  //   parentIcon: <DashboardIconFilled />,
  // },
  {
    name: "cpo dashboard",
    path: "/cpo-dashboard",
    permission: Permission.CPOD,
    allowedRoles: [RoleName.USER],
    icon: <DashboardIcon />,
    component: lazy(() => import("../pages/CPODashboard")),
    parentMenu: "Dashboard",
    parentIcon: <DashboardIconFilled />,
  },
  // {
  //   name: "dashboard",
  //   path: "/dashboard",
  //   permission: Permission.MD,
  //   allowedRoles: [RoleName.USER],
  //   icon: <DashboardIconFilled />,
  //   component: lazy(() => import("../pages/Dashboard")),
  //   parentMenu: "Dashboard",
  //   parentIcon: <DashboardIconFilled />,
  // },
  // {
  //   name: "TV View",
  //   path: "/tv-view",
  //   permission: Permission.TV,
  //   allowedRoles: [RoleName.USER],
  //   icon: <DashboardIcon />,
  //   component: lazy(() => import("../pages/CustomDashboard")),
  //   parentMenu: "Dashboard",
  // },
  {
    name: "cameras",
    path: "/siteView",
    permission: Permission.SV,
    allowedRoles: [RoleName.USER],
    icon: <SiteViewIcon />,
    component: lazy(() => import("../pages/SiteView")),
    parentMenu: "Site View",
    parentIcon: <SiteViewParentIcon />,
  },
  // {
  //   name: "project schedule",
  //   path: "/projectSchedule",
  //   permission: Permission.PS,
  //   allowedRoles: [RoleName.USER],
  //   icon: <ProjectScheduleIcon />,
  //   component: lazy(() => import("../pages/ProjectSchedule")),
  //   parentOverride: true,
  //   parentMenu: "project schedule",
  // },
  // {
  //   name: "gallery",
  //   path: "/gallery",
  //   permission: Permission.G,
  //   allowedRoles: [RoleName.USER],
  //   icon: <GalleryIcon />,
  //   component: lazy(() => import("../pages/Gallery")),
  //   parentOverride: true,
  //   parentMenu: "gallery",
  // },
  {
    name: "timelapse",
    path: "/timelapse",
    permission: Permission.TL,
    allowedRoles: [RoleName.USER],
    icon: <TimelapseIcon />,
    component: lazy(() => import("../pages/Timelapse")),
    parentMenu: "Site View",
  },
  {
    name: "activity review",
    path: "/activityreview",
    permission: Permission.TL,
    allowedRoles: [RoleName.SITEADMIN],
    icon: <ReviewIcon />,
    component: lazy(() => import("../pages/ActivityReview")),
    parentMenu: "Site View",
  },
  {
    name: "short range program",
    path: "/settings/shortRangePrograms",
    allowedRoles: [RoleName.USER],
    permission: Permission.SRP,
    icon: <SRPIcon />,
    component: lazy(() => import("../pages/SettingsShortRangePrograms")),
    parentMenu: "Program Delivery",
    parentIcon: <ProjectScheduleIcon />,
  },
  {
    name: "Platform List",
    path: "/platform/list",
    permission: Permission.PI,
    allowedRoles: [RoleName.USER],
    icon: <FormatListBulletedIcon />,
    component: lazy(() => import("../pages/Platform/PlatformList")),
    parentMenu: "Platform",
    parentIcon: <PlaylistAddCheckCircleIcon />,
  },
  {
    name: "Daily Inspection History",
    path: "/platform/inspect-history",
    permission: Permission.PI,
    allowedRoles: [RoleName.USER],
    icon: <FactCheckIcon />,
    component: lazy(
      () => import("../pages/Platform/PlatformInspectionHistory"),
    ),
    parentMenu: "Platform",
    parentIcon: <PlaylistAddCheckCircleIcon />,
  },

  {
    name: "Installation Declaration History",
    path: "/platform/installation-history",
    permission: Permission.PI,
    allowedRoles: [RoleName.USER],
    icon: <UpgradeIcon />,
    component: lazy(() => import("../pages/Platform/PlatformInstallationList")),
    parentMenu: "Platform",
    parentIcon: <PlaylistAddCheckCircleIcon />,
  },
  {
    name: "Platform Status",
    path: "/platform/inspect",
    permission: Permission.PI,
    allowedRoles: [RoleName.USER],
    hidden: true,
    icon: <SiteViewIcon />,
    component: lazy(() => import("../pages/Platform")),
    parentMenu: "Platform",
    parentIcon: <PlaylistAddCheckCircleIcon />,
  },
  {
    name: "long range program",
    path: "/settings/longRangePrograms",
    allowedRoles: [RoleName.SITEADMIN],
    permission: Permission.LRP,
    icon: <LRPIcon />,
    component: lazy(() => import("../pages/SettingsLongRangePrograms")),
    parentMenu: "Program Delivery",
  },
  {
    name: "cranecast stats",
    path: "/cranecastStats",
    icon: <CraneHookIcon />,
    allowedRoles: [RoleName.USER],
    permission: Permission.CH,
    component: lazy(() => import("../pages/CranecastStats")),
    parentMenu: "Cranecast",
    parentIcon: <CraneIcon />,
  },
  {
    name: "GPS Stream",
    path: "/gpsware",
    permission: Permission.TL,
    allowedRoles: [RoleName.SITEADMIN],
    icon: <ReviewIcon />,
    component: lazy(() => import("../pages/GPSWare/Coordinates")),
    parentMenu: "Cranecast",
  },
];

export const viewerMenuRoutes: MenuRoute[] = [
  {
    name: "timelapse",
    path: "/timelapse",
    permission: Permission.TL,
    allowedRoles: [RoleName.VIEWER],
    icon: <TimelapseIcon />,
    component: lazy(() => import("../pages/Timelapse")),
    parentOverride: true,
    parentMenu: "timelapse",
  },
];

export const viewerRoutes: Route[] = [
  {
    name: "timelapse",
    path: "/timelapse",
    permission: Permission.TL,
    allowedRoles: [RoleName.VIEWER],
    component: lazy(() => import("../pages/Timelapse")),
  },
  {
    name: "projects",
    path: "/projects",
    allowedRoles: [RoleName.VIEWER],
    component: lazy(() => import("../pages/Projects")),
  },
  // {
  //   name: "live",
  //   path: "/live",
  //   allowedRoles: [RoleName.VIEWER],
  //   permission: Permission.L,
  //   component: lazy(() => import("../pages/Live")),
  // },
];

export const adminRoutes: MenuRoute[] = [
  // {
  //   name: "daily dashboard",
  //   path: "/dailyDashboard",
  //   icon: <DashboardIcon />,
  //   allowedRoles: [RoleName.ADMIN, RoleName.SITEADMIN],
  //   permission: Permission.CS,
  //   component: lazy(() => import("../pages/CranecastDailyDashboard")),
  //   parentMenu: "Cranecast",
  // },
  {
    name: "cranecast timeline",
    path: "/cranecastTimeline",
    icon: <DashboardIcon />,
    allowedRoles: [RoleName.SITEADMIN, RoleName.ADMIN, RoleName.USER],
    permission: Permission.CT,
    component: lazy(() => import("../pages/CranecastTimeline")),
    parentMenu: "Cranecast",
    parentIcon: <CraneIcon />,
  },
  {
    name: "project zone",
    path: "/projectZone",
    icon: <SafetyCheckIcon />,
    allowedRoles: [RoleName.SITEADMIN],
    permission: Permission.TL,
    component: lazy(() => import("../pages/ProjectZone")),
    parentMenu: "Cranecast",
    parentIcon: <CraneIcon />,
  },
  {
    name: "lift review",
    path: "/craneLift",
    icon: <LiftReviewIcon />,
    allowedRoles: [RoleName.SITEADMIN],
    component: lazy(() => import("../pages/CraneLifts")),
    permission: Permission.CLR,
    parentMenu: "Cranecast",
    parentIcon: <CraneIcon />,
  },
  // Comment routes and page code
  // {
  //   name: "dashboard",
  //   path: "/cranecast",
  //   icon: <DashboardIcon />,
  //   allowedRoles: [RoleName.ADMIN, RoleName.SITEADMIN],
  //   permission: Permission.CCD,
  //   component: lazy(() => import("../pages/Cranecast")),
  //   parentMenu: "Cranecast",
  // },
  // {
  //   name: "crane schedule",
  //   path: "/craneSchedule",
  //   icon: <CraneScheduleIcon />,
  //   allowedRoles: [RoleName.ADMIN, RoleName.SITEADMIN],
  //   permission: Permission.CS,
  //   component: lazy(() => import("../pages/CraneSchedule")),
  //   parentMenu: "Cranecast",
  // },
  // {
  //   name: "section sensor",
  //   path: "/sectionSensor",
  //   permission: Permission.SS,
  //   allowedRoles: [RoleName.SITEADMIN],
  //   icon: <SectionSensorIcon />,
  //   component: lazy(() => import("../pages/SectionSensor")),
  //   parentOverride: true,
  //   parentMenu: "section sensor",
  // },
  {
    name: "settings",
    path: "/settings",
    permission: Permission.S,
    allowedRoles: [RoleName.SITEADMIN],
    icon: <SettingsIcon />,
    component: lazy(() => import("../pages/Settings")),
    parentOverride: true,
    parentMenu: "settings",
  },
];

export const settingRoutes: SettingRoute[] = [
  {
    name: "site",
    subRoutes: [
      {
        name: "locations",
        path: "/settings/locations",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsLocations")),
      },
      {
        name: "areas",
        path: "/settings/areas",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsAreas")),
      },
      {
        name: "activities",
        path: "/settings/activities",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsActivities")),
      },
      {
        name: "trade",
        path: "/settings/trade",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsTrade")),
      },
      {
        name: "material",
        path: "/settings/material",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsMaterial")),
      },
      {
        name: "suppliers",
        path: "/settings/suppliers",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsSuppliers")),
      },
      {
        name: "contractors",
        path: "/settings/contractors",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsContractors")),
      },
      // {
      //   name: "allocations",
      //   path: "/settings/allocations",
      //   allowedRoles: [RoleName.ADMIN],
      //   component: lazy(() => import("../pages/SettingsAllocations")),
      // },
      {
        name: "calendars",
        path: "/settings/calendars",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsCalendars")),
      },
      {
        name: "calendar dates",
        path: "/settings/calendarDates",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsCalendarDates")),
      },
      {
        name: "short range programs",
        path: "/settings/shortRangePrograms",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsShortRangePrograms")),
      },
      {
        name: "short range program activities",
        path: "/settings/shortRangeProgramActivities",
        allowedRoles: [RoleName.USER, RoleName.ADMIN],
        hidden: true,
        component: lazy(
          () => import("../pages/SettingsShortRangeProgramActivities"),
        ),
      },
      {
        name: "long range programs",
        path: "/settings/longRangePrograms",
        allowedRoles: [RoleName.SITEADMIN],
        component: lazy(() => import("../pages/SettingsLongRangePrograms")),
      },
      {
        name: "long range program activities",
        path: "/settings/longRangeProgramActivities",
        allowedRoles: [RoleName.SITEADMIN],
        hidden: true,
        component: lazy(
          () => import("../pages/SettingsLongRangeProgramActivities"),
        ),
      },
      {
        name: "custom configuration",
        path: "/settings/custom",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsCustom")),
      },
      // {
      //   name: "weekly history",
      //   path: "/settings/weeklyHistory",
      //   allowedRoles: [RoleName.ADMIN],
      //   hidden: true,
      //   component: lazy(() => import("../pages/SettingsWeeklyHistory")),
      // },
    ],
  },
  {
    name: "notifications",
    subRoutes: [
      {
        name: "address book",
        path: "/settings/addressBook",
        allowedRoles: [RoleName.ADMIN],
        component: lazy(() => import("../pages/SettingsNotificationUsers")),
      },
      // {
      //   name: "schedules",
      //   path: "/settings/schedules",
      //   allowedRoles: [RoleName.ADMIN],
      //   component: lazy(() => import("../pages/SettingsSchedules")),
      // },
      // {
      //   name: "schedule policies",
      //   path: "/settings/schedulePolicies",
      //   allowedRoles: [RoleName.ADMIN],
      //   component: lazy(() => import("../pages/SettingsSchedulePolicies")),
      // },
    ],
  },
  {
    name: "reports",
    subRoutes: [
      // {
      //   name: "daily updates",
      //   path: "/settings/dailyUpdates",
      //   allowedRoles: [RoleName.USER],
      //   component: lazy(() => import("../pages/SettingsDailyUpdates")),
      // },
      // {
      //   name: "man steel concrete",
      //   path: "/settings/hoursSteelConcrete",
      //   allowedRoles: [RoleName.USER],
      //   component: lazy(() => import("../pages/ManSteelConcrete")),
      //   permission: Permission.MSC,
      // },
    ],
  },
  {
    name: "devices",
    subRoutes: [
      {
        name: "cameras",
        path: "/settings/cameras",
        allowedRoles: [RoleName.SITEADMIN],
        component: lazy(() => import("../pages/Camera")),
      },
      {
        name: "cranes",
        path: "/settings/cranes",
        allowedRoles: [RoleName.SITEADMIN],
        component: lazy(() => import("../pages/Crane")),
      },
      {
        name: "wind sensors",
        path: "/settings/windSensors",
        allowedRoles: [RoleName.SITEADMIN],
        component: lazy(() => import("../pages/SettingsWindSensor")),
      },
      {
        name: "polygons",
        path: "/settings/polygons",
        allowedRoles: [RoleName.SITEADMIN],
        component: lazy(() => import("../pages/Polygon")),
      },
      {
        name: "Material Images",
        path: "/material-images",
        allowedRoles: [RoleName.SITEADMIN],
        component: lazy(() => import("../pages/MaterialImage")),
      },

      {
        name: "GPS",
        path: "/gps",
        allowedRoles: [RoleName.SITEADMIN],
        component: lazy(() => import("../pages/GPSWare")),
      },
    ],
  },
  // {
  //   name: "weeklyschedule",
  //   permission: Permission.WS,
  //   subRoutes: [
  //     {
  //       name: "weeklyschedule",
  //       path: "/settings/weeklyschedule",
  //       allowedRoles: [RoleName.USER],
  //       component: lazy(() => import("../pages/WeeklyScheduleActivity")),
  //       permission: Permission.WS,
  //     },
  //   ],
  // },
  //
  // {
  //   name: "pour breakdown",
  //   permission: Permission.PB,
  //   subRoutes: [
  //     {
  //       name: "pour breakdown",
  //       path: "/settings/pourBreakdown",
  //       allowedRoles: [RoleName.USER],
  //       component: lazy(() => import("../pages/PourBreakdown")),
  //       permission: Permission.PB,
  //     },
  //   ],
  // },
  // {
  //   name: "labour onsite",
  //   permission: Permission.TLO,
  //   subRoutes: [
  //     {
  //       name: "total labour onsite",
  //       path: "/settings/totallabouronsite",
  //       allowedRoles: [RoleName.USER],
  //       component: lazy(() => import("../pages/TotalLabourOnsite")),
  //       permission: Permission.TLO,
  //     },
  //   ],
  // },
];

export default (menuRoutes as Route[])
  .concat(
    settingRoutes.reduce((accumulation: Route[], current: SettingRoute) => {
      return accumulation.concat(current.subRoutes);
    }, []),
  )
  .concat([
    {
      name: "projects",
      path: "/projects",
      allowedRoles: [RoleName.USER],
      component: lazy(() => import("../pages/Projects")),
    },
    // {
    //   name: "live",
    //   path: "/live",
    //   permission: Permission.L,
    //   allowedRoles: [RoleName.USER],
    //   component: lazy(() => import("../pages/Live")),
    // },
    {
      name: "slideshow",
      path: "/slideshow",
      permission: Permission.LV,
      allowedRoles: [RoleName.USER],
      component: lazy(() => import("../pages/Slideshow")),
    },
    // {
    //   name: "survey submit",
    //   path: "/surveySubmit",
    //   allowedRoles: [RoleName.USER],
    //   component: lazy(() => import("../pages/SurveySubmit")),
    // },
  ]);
